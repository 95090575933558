exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-the-book-js": () => import("./../../../src/pages/about-the-book.js" /* webpackChunkName: "component---src-pages-about-the-book-js" */),
  "component---src-pages-book-artifacts-js": () => import("./../../../src/pages/book-artifacts.js" /* webpackChunkName: "component---src-pages-book-artifacts-js" */),
  "component---src-pages-book-luv-to-speak-js": () => import("./../../../src/pages/book-luv-to-speak.js" /* webpackChunkName: "component---src-pages-book-luv-to-speak-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-innovation-consulting-js": () => import("./../../../src/pages/innovation-consulting.js" /* webpackChunkName: "component---src-pages-innovation-consulting-js" */),
  "component---src-pages-new-release-js": () => import("./../../../src/pages/new-release.js" /* webpackChunkName: "component---src-pages-new-release-js" */),
  "component---src-pages-reader-reviews-js": () => import("./../../../src/pages/reader-reviews.js" /* webpackChunkName: "component---src-pages-reader-reviews-js" */)
}

